<template>
  <div class="flex-form">
    <el-form
      ref="contentForm"
      v-loading="loading"
      :model="contentForm"
      label-width="170px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Content
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Content
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Uid"
      >
        {{ contentUid }}
      </el-form-item>

      <el-form-item
        label="Active"
        prop="active"
      >
        <el-switch
          v-model="contentForm.isActive"
        />
      </el-form-item>

      <el-form-item
        label="Key"
        prop="contentKey"
      >
        <el-input v-model="contentForm.contentKey" />
      </el-form-item>

      <el-tabs
        v-model="activeLang"
        type="card"
      >
        <el-tab-pane
          v-for="(item, index) in contentForm.contentValues"
          :key="item.index"
          :label="langName(item.langCode)"
          :name="item.langCode"
        >
          <el-form-item
            label="Value"
            prop="contentValue"
          >
            <el-input v-model="contentForm.contentValues[index].value" />
          </el-form-item>
        </el-tab-pane>
      </el-tabs>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getContent,
  updateContent,
  createContent,
} from '@/api/content';
import { getAllLanguages } from '@/api/language';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';

export default {
  name: 'ContentDetails',
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    contentUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      contentForm: {
        contentKey: '',
        contentValues: [],
        // type: '',
        isActive: true,
      },
      content: {},
      langOptions: [],
      activeLang: '',
      rules: {
        contentKey: [{
          required: true,
          trigger: 'blur',
          message: 'Key is required',
        }],
        /* contentValue: [{
          required: true,
          trigger: 'blur',
          message: 'Value is required',
        }], */
      },
    };
  },
  watch: {
    contentUid(val) {
      if (val) {
        this.getContent(val);
      } else {
        this.initForm();
      }
    },
  },
  async created() {
    if (this.isEditMode) {
      await this.getContent(this.contentUid);
    } else {
      await this.initForm();
    }
  },
  methods: {
    async initForm() {
      this.resetForm();

      await this.getLanguages();

      this.langOptions.forEach((item) => {
        this.contentForm.contentValues.push({
          langCode: item.value,
          value: '',
        });
      });

      this.activeLang = this.langOptions[0].value;
    },
    langName(code) {
      const langName = this.langOptions.find((item) => item.value === code)?.label;
      const catName = this.contentForm.contentValues.find((item) => item.langCode === code)?.value;

      if (!catName.trim()) {
        return `! ${langName}`;
      }

      return langName;
    },
    async getContent(contentUid) {
      try {
        this.resetForm();
        this.loading = true;

        await this.getLanguages();

        const data = await getContent({ contentUid });

        this.content = cloneDeep(data.content);
        this.contentForm = data.content;

        // order content by langOptions
        this.contentForm.contentValues = this.langOptions.map((item) => {
          const content = this.contentForm.contentValues.find((c) => c.langCode === item.value);
          if (!content) {
            return {
              langCode: item.value,
              value: '',
            };
          }

          return content;
        });

        this.activeLang = this.contentForm.contentValues
          .find((item) => item.value.trim())?.langCode || this.langOptions[0].value;

        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
      }
    },
    async getLanguages() {
      try {
        const data = await getAllLanguages();
        this.langOptions = data.languages.map((item) => ({
          value: item.langCode,
          label: item.langName,
        }));
      } catch (e) {
        this.langOptions = [];
      }
    },
    async onEditSubmit() {
      this.$refs.contentForm.validate(async (valid) => {
        if (valid) {
          const updatedContent = makeUpdateObj(this.content, this.contentForm);
          if (Object.keys(updatedContent).length > 0) {
            try {
              this.saving = true;
              updatedContent.contentUid = this.contentUid;

              await updateContent(updatedContent);

              this.$message({
                type: 'success',
                message: 'Content has been saved successfully',
              });

              await this.getContent(this.contentUid);
              this.saving = false;

              this.$emit('update');
            } catch (e) {
              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onAddSubmit() {
      this.$refs.contentForm.validate(async (valid) => {
        if (valid) {
          try {
            this.saving = true;
            await createContent(this.contentForm);

            this.$message({
              type: 'success',
              message: 'Content has been added successfully',
            });

            this.resetForm();
            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.contentForm.resetFields();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
