import Vue from 'vue';

export function listContent(params) {
  return Vue.$http({
    url: '/content/listContent',
    method: 'get',
    params,
  });
}

export function getContent(params) {
  return Vue.$http({
    url: '/content/getContent',
    method: 'get',
    params,
  });
}

export function getContentItemsByKey(params) {
  return Vue.$http({
    url: '/content/getContentItemsByKey',
    method: 'get',
    params,
  });
}

export function updateContent(data) {
  return Vue.$http({
    url: '/content/updateContent',
    method: 'post',
    data,
  });
}

export function createContent(data) {
  return Vue.$http({
    url: '/content/createContent',
    method: 'post',
    data,
  });
}

export function deleteContent(data) {
  return Vue.$http({
    url: '/content/deleteContent',
    method: 'post',
    data,
  });
}

export function getAllContent(params) {
  return Vue.$http({
    url: '/content/getAllContent',
    method: 'get',
    params,
  });
}
